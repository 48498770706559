import { createElement } from 'react'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  UpOutlined,
} from '@ant-design/icons'
import { ComponentController } from './component_controller'

interface AntIconProps {
  icon: string
  className: string
}

const IconMap = {
  check: CheckCircleOutlined,
  close: CloseCircleOutlined,
  edit: EditOutlined,
  down: DownOutlined,
  exclamation: ExclamationCircleOutlined,
  up: UpOutlined,
}

const AntIcon = (props: AntIconProps) => {
  const { icon, className } = props
  return createElement(IconMap[icon], {
    className: className,
  })
}

export class AntIconController extends ComponentController {
  get component() {
    return AntIcon
  }
}
